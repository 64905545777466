import logo from './logo.svg';
import './App.css';
import { Routes, Route, BrowserRouter, Router } from "react-router-dom"
import Report from '../src/Component/Report/Report'
import Login from './Component/Login/Login';
import ChangePassword from './Component/Login/ChangePassword';
import ForgotPassword from './Component/Login/ForgotPassword';
import Registration from './Component/Report/Registration';
function App() {
  return (
    <div className="App">
         <BrowserRouter>
         <Routes>
         <Route path="/" element={<Login />} />
          <Route path="/report" element={<Report />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/registration" element={<Registration />} />
        </Routes>

     </BrowserRouter>
    </div>
  );
}

export default App;
