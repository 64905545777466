import { Component } from 'react';
import {
    Form,
    FormFeedback,
    FormGroup,
    FormText,
    Label,
    Input,
    Button,
} from 'reactstrap';
import './Login.css';
import {PostData} from '../Helper'
export default class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            validate: {
                emailState: '',
            },
            message:null,
            width:1000
        };
        this.handleChange = this.handleChange.bind(this);
    }
    
    handleChange = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;

        this.setState({
            [name]: value,
        });
    };

    validateEmail(e) {
        const emailRex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        const { validate } = this.state;

        if (emailRex.test(e.target.value)) {
            validate.emailState = 'has-success';
        } else {
            validate.emailState = 'has-danger';
        }

        this.setState({ validate });
    }

   async submitForm(e) {
        e.preventDefault();
        console.log(`Email: ${this.state.email}`);
        let data=await PostData("PowerBI/validatelogin",{ UserName: this.state.email, Password: this.state.password });
        if (data.success) {
            this.setState({ message: '' })
            sessionStorage.setItem("token",data.token);
            sessionStorage.setItem("username",this.state.email);
            sessionStorage.setItem("Name",data.firstName+' '+data.lastName);
            sessionStorage.setItem("IsAdmin",data.isAdmin);
            if(data.isForcedReset===true)
            {
                window.location.href="/changepassword"
            }
            else
            {
            window.location.href="/report"
            }
        }
        else {
            this.setState({ message: data.message })
        }

    }


    render() {
        const { email, password } = this.state;
        
        return (
            <>
            <div className="LoginHeader" style={{width:'100%'}}>
            {window.innerWidth<=768? <h1 style={{ textAlign: "center", 
					marginLeft: "10px", 
					color: "white", fontSize:15 }}>
			Power BI Report Dashboard
		</h1>:
		<h1 style={{ textAlign: "center", 
		marginLeft: "200px", 
		color: "white", fontSize:25 }}>
Power BI Report Dashboard
</h1>}
<div style={{position:"absolute",right:20}}>
<img src={require('../../assets/img/ACBLogo.jpg')} style={{width:100,height:75}}/></div>
            </div>
            <div className="Login">
                <h2>Sign In</h2>
                <Form className="form" onSubmit={(e) => this.submitForm(e)}>
                    <FormGroup>
                        <Label>Username</Label>
                        <Input
                            required={true}
                            type="email"
                            name="email"
                            id="Email"
                            placeholder="email@.com"
                            valid={this.state.validate.emailState === "has-success"}
                            invalid={this.state.validate.emailState === "has-danger"}
                            value={email}
                            onChange={(e) => {
                                this.validateEmail(e);
                                this.handleChange(e);
                            }}
                        />
                        <FormFeedback>
                            Uh oh! Looks like there is an issue with your email. Please input
                            a correct email.
                        </FormFeedback>
                       
                        <FormText>Your username is most likely your email.</FormText>
                    </FormGroup>
                    <FormGroup>
                        <Label for="Password">Password</Label>
                        <Input
                            required={true}
                            type="password"
                            name="password"
                            id="Password"
                            placeholder="********"
                            value={password}
                            onChange={(e) => this.handleChange(e)}
                        />
                        <span style={{color:'red'}}>
                            {this.state.message }
                        </span>
                    </FormGroup>
                    <br/>
                    <Button>LOG IN</Button>
                    <a href='/forgotpassword' style={{float:'right', textDecoration:'none'}}>Forgot Password</a>
                </Form>
            </div>
            <div className="footer">
          <div className="footer-text">
          ©2023 The Advertising Checking Bureau, Inc.


          </div>
        </div>
            </>
        );
    }
}
