import React, { useState,useEffect } from "react";
import "./Report.css";
import { Col, Button, Form, FormGroup, Label, Input, Table,FormFeedback } from 'reactstrap';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import {PostData,GetData} from '../Helper'
// Root Component to demonstrate usage of wrapper component
function Client() {
    const[ClientName, setClientName]=useState('');
    const[ClientID, setClientID]=useState('');
    const[TableList, setTableList]=useState([]);
    const[ID, setID]=useState(0);
    useEffect(() => {
      GetClient()
      },['Client']);
    async function GetClient() {
      try {
               
         let data=await GetData("Client/FunGetClient");
         if(data.message==="Unauthorized")
         {
        window.location.href='/'
         }
         else
         {
           if(data.success===true)
         {
         setTableList(data.lstClient);
         }
         } 
      } catch (error) {
       console.log(error);
      }
    }
    async function FunSetEdit(obj)
    {
      try {
        setClientName(obj.ClientName);
        setClientID(obj.ClientID);
        setID(obj.ID);
      } catch (error) {
        console.log(error);
      }
    }
    async function Clear()
    {
      try {
        setClientID('');
        setClientName('');
        setID(0);
      } catch (error) {
        console.log(error);
      }
    }
   async function onChangeClientID(e){
      const re = /^[0-9\b]+$/;
  
      // if value is not blank, then test the regex
  
      if (e.target.value === '' || re.test(e.target.value)) {
         setClientID(e.target.value);
      }
  }
  
  async function submitForm(e) {
    try {
      e.preventDefault();
      
      if(ID===0 || ID===null)
      {
        let ClientData=TableList.filter(x=>x.ClientName.toLowerCase()===ClientName.toLowerCase())
        if(ClientData.length>0)
        {
          alert('Client Name already exists');
          return false;
        }
         ClientData=TableList.filter(x=>x.ClientID.toString()===ClientID.toString())
        if(ClientData.length>0)
        {
          alert('Client ID already exists');
          return false;
        }
      }
      else
      {
        let ClientData=TableList.filter(x=>x.ClientName.toLowerCase()===ClientName.toLowerCase() && x.ID.toString()!==ID.toString())
        if(ClientData.length>0)
        {
          alert('Client Name already exists');
          return false;
        }
         ClientData=TableList.filter(x=>x.ClientID.toString()===ClientID.toString() && x.ID.toString()!==ID.toString())
        if(ClientData.length>0)
        {
          alert('Client ID already exists');
          return false;
        }
      }
      let data=await PostData("Client/SaveClient",{clientName:ClientName,clientId:ClientID,id:ID,mode:ID===0?"A":"M" });
      console.log(data);
      if (data.success) {
         alert('Data saved successfully.')
         Clear();
         GetClient(); 
      }
      else {
         
      }
    } catch (error) {
      console.log(error);
    }

}

async function DeleteItem(_ID)
{
try {
  let data=await PostData("Client/SaveClient",{id:_ID,mode:"D" });
      console.log(data);
      if (data.success) {
         alert('Row deleted successfully.');
         GetClient(); 
         Clear();
      }
} catch (error) {
  console.log(error);
}
}
    return (<>
          <Form className="form" onSubmit={(e) => submitForm(e)}>
            <br/>
           <h3 style={{textAlign:'left',marginLeft:'1%'}}>Add/Update Client</h3>
           <hr/>
        <FormGroup row>
          <Label for="txtClientID" sm={2}>Client ID <span class="required-field"></span></Label>
          <Col sm={3}>
            <Input type="text" name="ClientID" id="txtClientID" value={ClientID} maxLength={10} placeholder="Client ID" required={true} onChange={(e)=>onChangeClientID(e)} />
          </Col>
          <Label for="txtClientName" sm={2}>Client Name <span class="required-field"></span></Label>
          <Col sm={3}>
            <Input type="text" name="ClientName" id="txtClientName" value={ClientName} maxLength={100} placeholder="Client Name" required={true} onChange={(e)=>setClientName(e.target.value)} />
          </Col>
        </FormGroup>
       
        <FormGroup row>
        <Col sm={1}></Col>
        <Col sm={3}>
        <Button  color="primary" style={{marginRight:10}}>{ID!==0?"Update":"Save"}</Button>
        
        <Button type="button" color="danger" onClick={()=>Clear()}>{ID!==0?"Cancel":"Clear"}</Button>
        </Col>
  </FormGroup>
</Form>
<hr/>
<h4 style={{textAlign:'left',marginLeft:'2%'}}>Client Details</h4>
<div className="row" style={{padding:'1%'}}>
  <div className="col-sm-11">
  <Table bordered={true}> 
                <thead> 
                    <tr> 
                        <th>Client ID</th> 
                        <th>Client Name</th> 
                        <th></th>
                        <th></th>
                    </tr> 
                </thead> 
                <tbody>
                 {TableList.map((item,index) => (
                  <tr key={"TrClient"+index}>
                    <td>{item.ClientID}</td>
                    <td>{item.ClientName}</td>
                   
                    <td><i class="fa fa-edit" style={{fontSize:24}} onClick={()=>FunSetEdit(item)}></i></td>
                    <td><i class="fa fa-trash" style={{fontSize:24}} onClick={()=>window.confirm("Are you sure you want to delete this item?")?DeleteItem(item.ID):null}></i></td>
                  </tr>
))}
                </tbody>
                </Table>
                <br/>
                <br/>
  </div>
</div>
    </>);
}
export default Client;