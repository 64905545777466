import React, { useState,useEffect } from "react";
import "./Report.css";
import { Col, Button, Form, FormGroup, Label, Input, Table,FormFeedback } from 'reactstrap';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import {PostData,GetData} from '../Helper'
import Multiselect from 'multiselect-react-dropdown'
// Root Component to demonstrate usage of wrapper component
function Registration() {
    const[FirstName, setFirstName]=useState('');
    const[LastName, setLastName]=useState('');
    const[Email, setEmail]=useState('');
    const[ClientID, setClientID]=useState(null);
    const[PhoneNo, setPhoneNo]=useState('');
    const[IsActive, setIsActive]=useState(true);
    const[ClientList, setClientList]=useState([]);
    const[ClientListMulti, setClientListMulti]=useState([]);
    const[SelectedClientList, setSelectedClientList]=useState([]);
    const[TableList, setTableList]=useState([]);
    const[ID, setID]=useState(0);
    const[validate,setvalidate]=useState({
      emailState: '',
  });
    useEffect(() => {
      GetRegistration()
      },['Registration']);
    async function GetRegistration() {
      try {
       
        
         let data=await GetData("PowerBI/FunGetRegistration");
         if(data.message==="Unauthorized")
         {
        window.location.href='/'
         }
         else
         {
           if(data.success===true)
         {
         setTableList(data.lstRegistration);
         setClientList(data.lstClient);
         let lstClientMulti=[];
         data.lstClient.forEach(element => {
          var obj={};
          obj["id"]=element.ClientID;
          obj["name"]=element.ClientName;
          lstClientMulti.push(obj);
         });
         setClientListMulti(lstClientMulti);
         }
         } 
      } catch (error) {
       console.log(error);
      }
    }
    async function FunSetEdit(obj)
    {
      try {
        setFirstName(obj.FirstName);
        setLastName(obj.LastName);
        setEmail(obj.Email);
       // setClientID(obj.ClientID);
       let _SelectedClientList=[];
        if(obj.ClientID!=="")
        {
          let lCleintList=obj.ClientID.split(',');
          let _ClientListMulti=[...ClientListMulti]          
          for (let index = 0; index <= lCleintList.length-1; index++) {
            let filterdata=_ClientListMulti.filter(x=>x.id.toString()===lCleintList[index])
            if(filterdata.length>0)
            {
              _SelectedClientList.push(filterdata[0]);
            }
            
          }
         
        }
        setSelectedClientList(_SelectedClientList);
        setPhoneNo(obj.PhoneNo);
        setIsActive(obj.IsActive);
        setID(obj.ID);
      } catch (error) {
        console.log(error);
      }
    }
    async function Clear()
    {
      try {
        setFirstName('');
        setLastName('');
        setEmail('');
        setClientID('');
        setPhoneNo('');
        setIsActive('');
        setID(0);
        setSelectedClientList([]);
      } catch (error) {
        console.log(error);
      }
    }
  async function  validateEmail(e) {
      const emailRex =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      let _validate  = {...validate};

      if (emailRex.test(e.target.value)) {
        _validate.emailState = 'has-success';
      } else {
        _validate.emailState = 'has-danger';
      }

      setvalidate({ _validate });
  }
  async function submitForm(e) {
    try {
      e.preventDefault();
      let clientids='';
      for (let index = 0; index <= SelectedClientList.length-1; index++) {
        if(clientids==="")
        {
          clientids=SelectedClientList[index].id.toString();
        }
        else
        {
          clientids=clientids+","+SelectedClientList[index].id.toString();
        }
        
      }
      if(clientids==="")
      {
        alert('Please select client');
        return false;
      }
      if(ID===0 || ID===null)
      {
        let CheckEmail=TableList.filter(x=>x.Email.toLowerCase()===Email.toLowerCase())
        if(CheckEmail.length>0)
        {
          alert('Email already exists');
          return false;
        }
      }
      let data=await PostData("PowerBI/SaveRegistration",{firstName:FirstName,lastName:LastName,email:Email,PhoneNo:PhoneNo,iSActive:IsActive,clientId:clientids,userID:ID,mode:ID===0?"A":"M" });
      console.log(data);
      if (data.success) {
         alert('Data saved successfully.')
         Clear();
         GetRegistration(); 
      }
      else {
         
      }
    } catch (error) {
      console.log(error);
    }

}
async function DeleteItem(_ID)
{
try {
  let data=await PostData("PowerBI/SaveRegistration",{userID:_ID,mode:"D" });
      console.log(data);
      if (data.success) {
         alert('Row deleted successfully.');
         GetRegistration(); 
         Clear();
      }
} catch (error) {
  console.log(error);
}
}
function onSelect(selectedList, selectedItem) {
  setSelectedClientList(selectedList);
}

function onRemove(selectedList, removedItem) {
  setSelectedClientList(selectedList);
}

    return (<>
          <Form className="form" onSubmit={(e) => submitForm(e)}>
            <br/>
           <h3 style={{textAlign:'left',marginLeft:'1%'}}>Add/Update User</h3>
           <hr/>
        <FormGroup row>
          <Label for="txtFirstName" sm={2}>First Name <span class="required-field"></span></Label>
          <Col sm={3}>
            <Input type="text" name="FirstName" id="txtFirstName" value={FirstName} maxLength={100} placeholder="First Name" required={true} onChange={(e)=>setFirstName(e.target.value)} />
          </Col>
          <Label for="txtLastName" sm={2}>Last Name <span class="required-field"></span></Label>
          <Col sm={3}>
            <Input type="text" name="LastName" id="txtLastName" value={LastName} maxLength={100} placeholder="Last Name" required={true} onChange={(e)=>setLastName(e.target.value)} />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="txtEmail" sm={2}>Email <span class="required-field"></span></Label>
          <Col sm={3}>
          <Input
                            required={true}
                            type="email"
                            name="email"
                            id="Email"
                            placeholder="email@.com"
                            valid={validate.emailState === "has-success"}
                            invalid={validate.emailState === "has-danger"}
                            value={Email}
                            onChange={(e) => {
                                validateEmail(e);
                                setEmail(e.target.value);
                            }}
                        />
                        <FormFeedback>
                            Uh oh! Looks like there is an issue with your email. Please input
                            a correct email.
                        </FormFeedback>
            </Col>
          <Label for="lblPhoneNo" sm={2}>Phone No</Label>
          <Col sm={3}>
          <PhoneInput
          maxLength={20}
          className="form-control"
      placeholder="Enter phone number"
      value={PhoneNo}
      onChange={setPhoneNo}/>
 </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="drpClient" sm={2}>Client(s) <span class="required-field"></span></Label>
          <Col sm={3}>
         {/*<Input isMulti type="select" same="select" id="drpClient" required={true} value={ClientID} onChange={(e)=>setClientID(e.target.value)}>
          <option>Select</option>
          {ClientList.map((item,index) => (
            <option value={item.ClientID}>{item.ClientName}</option>
          ))}
          </Input>
*/} 
<Multiselect
options={ClientListMulti} // Options to display in the dropdown
selectedValues={SelectedClientList} // Preselected value to persist in dropdown
onSelect={onSelect} // Function will trigger on select event
onRemove={onRemove} // Function will trigger on remove event
displayValue="name" // Property name to display in the dropdown options
showCheckbox={true}
/>

          </Col>
         
          <Col sm={5}>
          <Label check>
            <Input type="checkbox" checked={IsActive} onChange={(e)=>setIsActive(!IsActive)} />{' '}
            Active
          </Label>
          </Col>
        </FormGroup>
        <FormGroup row>
        <Col sm={1}></Col>
        <Col sm={3}>
        <Button  color="primary" style={{marginRight:10}}>{ID!==0?"Update":"Save"}</Button>
        
        <Button type="button" color="danger" onClick={()=>Clear()}>{ID!==0?"Cancel":"Clear"}</Button>
        </Col>
  </FormGroup>
</Form>
<hr/>
<h4 style={{textAlign:'left',marginLeft:'2%'}}>User Details</h4>
<div className="row" style={{padding:'1%', height:400,overflow:'auto'}}>
  <div className="col-sm-11">
  <Table bordered={true}> 
                <thead> 
                    <tr> 
                        <th>First Name</th> 
                        <th>Last Name</th> 
                        <th>Email</th> 
                        <th>Phone</th> 
                        <th></th>
                        <th></th>
                    </tr> 
                </thead> 
                <tbody>
                 {TableList.map((item,index) => (
                  <tr key={"TrRegistration"+index}>
                    <td>{item.FirstName}</td>
                    <td>{item.LastName}</td>
                    <td>{item.Email}</td>
                    <td>{item.PhoneNo}</td>
                   
                    <td><i class="fa fa-edit" style={{fontSize:24}} onClick={()=>FunSetEdit(item)}></i></td>
                    <td><i class="fa fa-trash" style={{fontSize:24}} onClick={()=>window.confirm("Are you sure you want to delete this item?")?DeleteItem(item.ID):null}></i></td>
                  </tr>
))}
                </tbody>
                </Table>
                <br/>
                <br/>
  </div>
</div>
    </>);
}
export default Registration;