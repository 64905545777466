import React, { useState,useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import { IconContext } from "react-icons/lib";

const Nav = styled.div`
background: #15171c;
height: 80px;
display: flex;
justify-content: flex-start;
align-items: center;
`;

const NavIcon = styled(Link)`
margin-left: 2rem;
font-size: 2rem;
height: 80px;
display: flex;
justify-content: flex-start;
align-items: center;
`;

const SidebarNav = styled.nav`
background: #15171c;
width: 250px;
height: 100vh;
display: flex;
justify-content: center;
position: fixed;
top: 0;
left: ${({ sidebar }) => (sidebar ? "0" : "-100%")};
transition: 350ms;
z-index: 10;
`;

const SidebarWrap = styled.div`
width: 100%;
`;

const Sidebar = (props) => {
const [sidebar, setSidebar] = useState(false);

const showSidebar = () => setSidebar(!sidebar);
const [width, setWidth] = useState(window.innerWidth);

function handleWindowSizeChange() {
    setWidth(window.innerWidth);
}
useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);
function FunCallBack(_ID)
{
	props.FunCallBackChild(_ID);
	setSidebar(false);
}
function FunRegistrationCallBack(_ID)
{
	props.FunRegistrationCallBack(_ID);
	setSidebar(false);
}
function FunClientCallBack(_ID)
{
	props.FunClientCallBack(_ID);
	setSidebar(false);
}
function FunReportRegistrationCallBack()
{
	props.FunReportRegistrationCallBack();
	setSidebar(false);
}
function FunLogout()
{
	sessionStorage.clear();
	window.location.href="/";
}
return (
	<>
	<IconContext.Provider value={{ color: "#fff" }}>
		<Nav>
		<NavIcon to="#">
			<FaIcons.FaBars onClick={showSidebar} />
		</NavIcon>
		{width<=768? <h1 style={{ textAlign: "center", 
					marginLeft: "10px", 
					color: "white", fontSize:15 }}>
			Power BI Report Dashboard
		</h1>:
		<h1 style={{ textAlign: "center", 
		marginLeft: "200px", 
		color: "white", fontSize:25 }}>
Power BI Report Dashboard
</h1>}

<div style={{position:"absolute",right:20}}>
<img src={require('../../assets/img/ACBLogo.jpg')} style={{width:100,height:75}}/></div>
		</Nav>
		<SidebarNav sidebar={sidebar}>
		<SidebarWrap>
			<NavIcon to="#">
			<AiIcons.AiOutlineClose onClick={showSidebar} />
			</NavIcon>
			<div style={{color:'#ffffff',fontWeight:'bold',  borderBottom:'1px solid',padding:'6px 3px',textAlign:'center'}}>Welcome: {sessionStorage.getItem('Name')}</div>
			<div style={{color:'#ffffff', borderTop:'1px solid', borderBottom:'1px solid',marginBottom:13,padding:'6px 0'}} >
				
				<div onClick={()=>FunLogout()} style={{cursor:'pointer', borderBottom:'1px solid',paddingBottom:6}}>Logout</div>
				<div style={{color:'#FFFFFF',textDecoration:'none',paddingTop:6}}><a href="/changepassword" style={{color:'#FFFFFF',textDecoration:'none',paddingTop:6}}>Change Password</a></div>
				{sessionStorage.getItem("IsAdmin")==='true'?
				<>
				<div class={props.IsRegistration===true? "active":null} style={{color:'#FFFFFF',textDecoration:'none',paddingTop:3,paddingBottom:3}} onClick={()=>FunRegistrationCallBack()}><a  style={{color:'#FFFFFF',textDecoration:'none',paddingTop:6,cursor:'pointer'}}>Add New User</a></div>
				<div class={props.IsClient===true? "active":null} style={{color:'#FFFFFF',textDecoration:'none',paddingTop:3,paddingBottom:3}} onClick={()=>FunClientCallBack()}><a  style={{color:'#FFFFFF',textDecoration:'none',paddingTop:3,cursor:'pointer'}}>Add/Update Client</a></div>
				<div class={props.IsReportRegistration===true? "active":null} style={{color:'#FFFFFF',textDecoration:'none',paddingTop:3,paddingBottom:3}} onClick={()=>FunReportRegistrationCallBack()}><a  style={{color:'#FFFFFF',textDecoration:'none',paddingTop:3,cursor:'pointer'}}>Reports Mapping</a></div>
				</>
				:null}
				</div>
				<div style={{height:'65vh',overflowX:'auto', overflowY:'none'}}>
				<ul class="menu">
{props.ReportList.map((item, index) => (
	<>
	<li key={"ReportMenu"+index} class={props.LocalReportID===item.reportID && props.IsRegistration!==true && props.IsClient!==true && props.IsReportRegistration!==true? "active":null}><a href="#" onClick={()=>FunCallBack(item.reportID)}>{item.reportName}</a></li>	
	</>	
))}
</ul>
</div>
		</SidebarWrap>
		</SidebarNav>
	</IconContext.Provider>
	</>
);
};

export default Sidebar;
