import Config from '../Component/Config.json'

export  async function GetData(EndPointName)
{
    const response = await fetch(Config.ApiURL+"/"+EndPointName,{
        method: 'GET',
        headers: {
          'Authorization': 'Bearer '+sessionStorage.getItem('token'),
          
        },
      });
        const data = await response.json();
        return data;
}

export  async function PostData(EndPointName,Body)
{
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json','Authorization': 'Bearer '+sessionStorage.getItem('token') },
        body: JSON.stringify(Body)
    };
    const response = await fetch(Config.ApiURL+"/"+EndPointName,requestOptions);
        const data = await response.json();
        return data;
}