import { Component } from 'react';
import {
    Form,
    FormFeedback,
    FormGroup,
    FormText,
    Label,
    Input,
    Button,
} from 'reactstrap';
import './Login.css';
import PasswordStrengthBar from 'react-password-strength-bar';
import {PostData} from '../Helper'
export default class ChangePassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            newPassword: '',
            confirmPassword: '',
            message:null,
            width:1000
        };
        this.handleChange = this.handleChange.bind(this);
    }
    
    handleChange = (event) => {
        const { target } = event;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const { name } = target;

        this.setState({
            [name]: value,
        });
    };

    validateEmail(e) {
        const emailRex =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        const { validate } = this.state;

        if (emailRex.test(e.target.value)) {
            validate.emailState = 'has-success';
        } else {
            validate.emailState = 'has-danger';
        }

        this.setState({ validate });
    }

   async submitForm(e) {
        e.preventDefault();
      if(this.state.confirmPassword!==this.state.newPassword)
      {
        this.setState({ message: 'Passwords do NOT match' });
        return false;
      }
      else
      {
        this.setState({ message: '' })
      }
       
        const data = await PostData("PowerBI/ChangePassword",{ email: sessionStorage.getItem('username'), password: this.state.password,newpassword:this.state.newPassword });
        if(data.message==="Unauthorized")
         {
 window.location.href='/'
         }
         else
         {
        if (data.success) {
            this.setState({ message: '' })
            sessionStorage.setItem("token",data.token);
            sessionStorage.setItem("username",this.state.email);
            alert('Password changed successfully. please login again');
            window.location.href="/"
        }
        else
        {
            this.setState({ message: data.message }) 
        }
    }

    }


    render() {
        const { password,newPassword,confirmPassword } = this.state;
        
        return (
            <>
            <div className="LoginHeader" style={{width:'100%'}}>
            {window.innerWidth<=768? <h1 style={{ textAlign: "center", 
					marginLeft: "10px", 
					color: "white", fontSize:15 }}>
			Power BI Report Dashboard
		</h1>:
		<h1 style={{ textAlign: "center", 
		marginLeft: "200px", 
		color: "white", fontSize:25 }}>
Power BI Report Dashboard
</h1>}
<div style={{position:"absolute",right:20}}>
<img src={require('../../assets/img/ACBLogo.jpg')} style={{width:100,height:75}}/></div>
            </div>
            <div className="Login">
                <h2>Change Password</h2>
                <Form className="form" onSubmit={(e) => this.submitForm(e)}>
                   
                    <FormGroup>
                        <Label for="Password">Password</Label>
                        <Input
                            required={true}
                            type="password"
                            name="password"
                            id="Password"
                            placeholder="********"
                            value={password}
                            onChange={(e) => this.handleChange(e)}
                        />
                       
                    </FormGroup>
                    <FormGroup>
                        <Label for="NewPassword">New Password</Label>
                        <Input
                            required={true}
                            type="password"
                            name="newPassword"
                            id="newPassword"
                            placeholder="********"
                            value={newPassword}
                            onChange={(e) => this.handleChange(e)}
                            maxLength={15}
                        />
                       <PasswordStrengthBar password={newPassword} />

                    </FormGroup>
                    <FormGroup>
                        <Label for="Confirmpassword">Confirm Password</Label>
                        <Input
                            required={true}
                            type="password"
                            name="confirmPassword"
                            id="confirmPassword"
                            placeholder="********"
                            value={confirmPassword}
                            onChange={(e) => this.handleChange(e)}
                            maxLength={15}
                        />
                        <span style={{color:'red'}}>
                            {this.state.message }
                        </span>
                    </FormGroup>
                    <br/>
                    <Button>Save</Button>
                    <a href='/report' style={{float:'right', textDecoration:'none'}}>Back To Report</a>
                </Form>
            </div>
            <div className="footer">
          <div className="footer-text">
          ©2023 The Advertising Checking Bureau, Inc.


          </div>
        </div>
            </>
        );
    }
}
