import React, { useState,useEffect } from "react";
import "./Report.css";
import { Col, Button, Form, FormGroup, Label, Input, Table,FormFeedback } from 'reactstrap';
import 'react-phone-number-input/style.css'
import {PostData,GetData} from '../Helper'
// Root Component to demonstrate usage of wrapper component
function ReportRegistration() {
    const[ClientName, setClientName]=useState('');
    const[ClientID, setClientID]=useState('');
    const[ReportName, setReportName]=useState('');
    const[GroupID, setGroupID]=useState('');
    const[ReportID, setReportID]=useState('');
    const[IsActive, setIsActive]=useState(false);
    const[ClientList, setClientList]=useState([]);
    const[TableList, setTableList]=useState([]);
    const[ID, setID]=useState(0);
    useEffect(() => {
      setClientName('');
        setClientID('');
      GetClient();
      },['Report']);
      async function GetClient() {
        try {
                 
           let data=await GetData("Client/FunGetClient");
           if(data.message==="Unauthorized")
           {
          window.location.href='/'
           }
           else
           {
             if(data.success===true)
           {
           setClientList(data.lstClient);
           }
           } 
        } catch (error) {
         console.log(error);
        }
      }
    async function GetReport(_ClientID) {
      try {
       
        
         let data=await GetData("Report/FunGetReport?ClientID="+_ClientID);
         if(data.message==="Unauthorized")
         {
        window.location.href='/'
         }
         else
         {
           if(data.success===true)
         {
         setTableList(data.lstReport);
         }
         } 
      } catch (error) {
       console.log(error);
      }
    }
    async function FunSetEdit(obj)
    {
      try {
        setClientName(obj.CustomerName);
        setClientID(obj.ClientID);
        setGroupID(obj.GroupID);
        setReportID(obj.ReportID);
        setReportName(obj.ReportName);
        setIsActive(obj.IsActive);
        setID(obj.ID);
      } catch (error) {
        console.log(error);
      }
    }
    async function Clear()
    {
      try {
        
        setGroupID('');
        setReportID('');
        setReportName('');
        setIsActive(true);
        setID(0);
      } catch (error) {
        console.log(error);
      }
    }
   
  
  async function submitForm(e) {
    try {
      e.preventDefault();
      
      if(ID===0 || ID===null)
      {
        let ReportData=TableList.filter(x=>x.ReportName.toLowerCase()===ReportName.toLowerCase())
        if(ReportData.length>0)
        {
          alert('Menu Name already exists');
          return false;
        }
        ReportData=TableList.filter(x=>x.GroupID.toString()===GroupID.toString() || x.ReportID.toString()===ReportID.toString())
        if(ReportData.length>0)
        {
          alert('Workspace/Report ID already exists');
          return false;
        }
      }
      else
      {
        
        let ReportData=TableList.filter(x=>x.ReportName.toLowerCase()===ReportName.toLowerCase() && x.ID.toString()!==ID.toString())
        if(ReportData.length>0)
        {
          alert('Menu Name already exists');
          return false;
        }
        ReportData=TableList.filter(x=>(x.GroupID.toString()===GroupID.toString() || x.ReportID.toString()===ReportID.toString()) && x.ID.toString()!==ID.toString())
        if(ReportData.length>0)
        {
          alert('Workspace/Report ID already exists');
          return false;
        }
      }
      if(ClientID==="" || ClientID===null)
      {
        alert('Please select Client.');
        return false;
      }
      let data=await PostData("Report/SaveReport",{customerName:ClientName,clientId:ClientID,reportName:ReportName,groupID:GroupID,reportID:ReportID,isActive:IsActive,id:ID,mode:ID===0?"A":"M" });
      console.log(data);
      if (data.success) {
         alert('Data saved successfully.')
         Clear();
         GetReport(ClientID); 
      }
      else {
         
      }
    } catch (error) {
      console.log(error);
    }

}

function FunSetClient(event)
{
  Clear();
  var index = event.nativeEvent.target.selectedIndex;
  setClientName(event.nativeEvent.target[index].text);
  setClientID(event.target.value)
  if(event.target.value==="")
  {
    GetReport(0);
  }
  else
  {
    GetReport(event.target.value);
  }
}
async function DeleteItem(_ID)
{
try {
  let data=await PostData("Report/SaveReport",{id:_ID,mode:"D" });
      console.log(data);
      if (data.success) {
         alert('Row deleted successfully.');
         GetReport(ClientID); 
         Clear();
      }
} catch (error) {
  console.log(error);
}
}

    return (<>
          <Form className="form" onSubmit={(e) => submitForm(e)}>
            <br/>
           <h3 style={{textAlign:'left',marginLeft:'1%'}}>Reports Mapping</h3>
           <hr/>
           <FormGroup row>
          <Label for="drpClient" sm={2}>Client <span class="required-field"></span></Label>
          <Col sm={3}>
         <Input  type="select" name="select" id="drpClient" required={true} value={ClientID} onChange={(e)=>FunSetClient(e)}>
          <option value="">Select</option>
          {ClientList.map((item,index) => (
            <option value={item.ClientID}>{item.ClientName}</option>
          ))}
          </Input>
</Col>
</FormGroup>
        <FormGroup row>
          <Label for="txtReportName" sm={2}>Menu Name <span class="required-field"></span></Label>
          <Col sm={3}>
            <Input type="text" name="ReportName" id="txtReportName" value={ReportName} maxLength={100} placeholder="Menu Name" required={true} onChange={(e)=>setReportName(e.target.value)} />
          </Col>
          <Label for="txtGroupID" sm={2}>Workspace ID <span class="required-field"></span></Label>
          <Col sm={3}>
            <Input type="text" name="GroupID" id="txtGroupID" value={GroupID} maxLength={100} placeholder="Group ID" required={true} onChange={(e)=>setGroupID(e.target.value)} />
          </Col>
         
        </FormGroup>
        <FormGroup row>
        <Label for="txtReportID" sm={2}>Report ID <span class="required-field"></span></Label>
          <Col sm={3}>
            <Input type="text" name="ReportID" id="txtReportID" value={ReportID} maxLength={100} placeholder="Report ID" required={true} onChange={(e)=>setReportID(e.target.value)} />
          </Col>
          
        <Col sm={2}>
          <Label check>
            <Input type="checkbox" checked={IsActive} onChange={(e)=>setIsActive(!IsActive)} />{' '}
            Active
          </Label>
          </Col>
        </FormGroup>
        <FormGroup row>
        <Col sm={1}></Col>
        <Col sm={3}>
        <Button  color="primary" style={{marginRight:10}}>{ID!==0?"Update":"Save"}</Button>
        
        <Button type="button" color="danger" onClick={()=>Clear()}>{ID!==0?"Cancel":"Clear"}</Button>
        </Col>
  </FormGroup>
</Form>
<hr/>
<h4 style={{textAlign:'left',marginLeft:'2%'}}>Reports Mapping Details</h4>
<div className="row" style={{padding:'1%'}}>
  <div className="col-sm-11">
  <Table bordered={true}> 
                <thead> 
                    <tr> 
                        <th>Report Name</th> 
                        <th>Workspace ID</th> 
                        <th>Report ID</th> 
                        <th></th>
                        <th></th>
                    </tr> 
                </thead> 
                <tbody>
                 {TableList.map((item,index) => (
                  <tr key={"TrReport"+index}>
                    <td>{item.ReportName}</td>
                    <td>{item.GroupID}</td>
                    <td>{item.ReportID}</td>                   
                    <td><i class="fa fa-edit" style={{fontSize:24}} onClick={()=>FunSetEdit(item)}></i></td>
                    <td><i class="fa fa-trash" style={{fontSize:24}} onClick={()=>window.confirm("Are you sure you want to delete this item?")?DeleteItem(item.ID):null}></i></td>
                  </tr>
))}
                </tbody>
                </Table>
                <br/>
                <br/>
  </div>
</div>
    </>);
}
export default ReportRegistration;